@font-face {
font-family: '__montserrat_f72026';
src: url(/_next/static/media/79e2438be9fabbbb-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__montserrat_f72026';
src: url(/_next/static/media/c6d26946fe0a9fae-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__montserrat_f72026';
src: url(/_next/static/media/53526f038fd4d750-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__montserrat_Fallback_f72026';src: local("Arial");ascent-override: 85.56%;descent-override: 22.19%;line-gap-override: 0.00%;size-adjust: 113.13%
}.__className_f72026 {font-family: '__montserrat_f72026', '__montserrat_Fallback_f72026'
}

